import React from "react";
import { LayoutWhite } from "../components/layouts/layout-white";
import { Container, Row, Col } from "react-bootstrap";
import book from "../images/book.png";
import ScrollIndicator from "../components/shared/scroll-indicator";
import SEO from "../components/shared/seo";

function PrivacyPolicy() {
  return (
    <>
      <SEO title="Privacy Policy" />
      <LayoutWhite>
        <div
          id="privacy-policy-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row className="legal">
              <Col sm={12} md={10} lg={8}>
                <h6>Legal</h6>
                <h2>Policy</h2>
                <p>
                  Diviv Group is committed to providing quality services to you
                  and this policy outlines our ongoing obligations to you in
                  respect of how we manage your Personal Information.
                </p>

                <p>
                  We have adopted the Australian Privacy Principles (APPs)
                  contained in the Privacy Act 1988 (Cth) (the Privacy Act). The
                  NPPs govern the way in which we collect, use, disclose, store,
                  secure and dispose of your Personal Information.
                </p>

                <p>
                  A copy of the Australian Privacy Principles may be obtained
                  from the website of The Office of the Australian Information
                  Commissioner at{" "}
                  <a href="https://www.oaic.gov.au">www.oaic.gov.au</a>.
                </p>

                <h5>What is personal information and why do we collect it?</h5>
                <p>
                  Personal Information is information or an opinion that
                  identifies an individual. Examples of Personal Information we
                  collect include: names, addresses, email addresses and phone
                  numbers.
                </p>

                <p>
                  This Personal Information is obtained in many ways including
                  by telephone, by email, via our website www.diviv.com.au from
                  your website, from media and publications, from other publicly
                  available sources, from cookies - delete all that aren’t
                  applicable and from third parties. We don’t guarantee website
                  links or policy of authorised third parties.
                </p>

                <p>
                  We collect your Personal Information for the primary purpose
                  of providing our services to you, providing information to our
                  clients and marketing. We may also use your Personal
                  Information for secondary purposes closely related to the
                  primary purpose, in circumstances where you would reasonably
                  expect such use or disclosure. You may unsubscribe from our
                  mailing/marketing lists at any time by contacting us in
                  writing.
                </p>

                <p>
                  When we collect Personal Information we will, where
                  appropriate and where possible, explain to you why we are
                  collecting the information and how we plan to use it.
                </p>

                <h5>Sensitive information</h5>
                <p>
                  Sensitive information is defined in the Privacy Act to include
                  information or opinion about such things as an individual's
                  racial or ethnic origin, political opinions, membership of a
                  political association, religious or philosophical beliefs,
                  membership of a trade union or other professional body,
                  criminal record or health information.
                </p>

                <p>Sensitive information will be used by us only:</p>

                <ul>
                  <li>For the primary purpose for which it was obtained</li>
                  <li>
                    For a secondary purpose that is directly related to the
                    primary purpose
                  </li>
                  <li>
                    With your consent; or where required or authorised by law
                  </li>
                </ul>

                <h5>Third parties</h5>
                <p>
                  Where reasonable and practicable to do so, we will collect
                  your Personal Information only from you. However, in some
                  circumstances we may be provided with information by third
                  parties. In such a case we will take reasonable steps to
                  ensure that you are made aware of the information provided to
                  us by the third party.
                </p>

                <h5>Disclosure of personal information</h5>
                <p>
                  Your Personal Information may be disclosed in a number of
                  circumstances including the following:
                </p>
                <ul>
                  <li>
                    Third parties where you consent to the use or disclosure;
                    and
                  </li>
                  <li>Where required or authorised by law.</li>
                </ul>

                <h5>Access to your personal information</h5>
                <p>
                  You may access the Personal Information we hold about you and
                  to update and/or correct it, subject to certain exceptions. If
                  you wish to access your Personal Information, please contact
                  us in writing.
                </p>

                <p>
                  Diviv Group will not charge any fee for your access request,
                  but may charge an administrative fee for providing a copy of
                  your Personal Information.
                </p>
                <p>
                  In order to protect your Personal Information we may require
                  identification from you before releasing the requested
                  information.
                </p>

                <h5>Maintaining the quality of your personal information</h5>
                <p>
                  It is an important to us that your Personal Information is up
                  to date. We will take reasonable steps to make sure that your
                  Personal Information is accurate, complete and up-to-date. If
                  you find that the information we have is not up to date or is
                  inaccurate, please advise us as soon as practicable so we can
                  update our records and ensure we can continue to provide
                  quality services to you.
                </p>

                <h5>Policy updates</h5>
                <p>
                  This Policy may change from time to time and is available on
                  our website.
                </p>

                <div class="legal__contact-information">
                  <h5>Privacy policy complaints and enquiries</h5>
                  <p>
                    If you have any queries or complaints about our Privacy
                    Policy please contact us at:
                  </p>

                  <span>165/15 Aberdeen St, Perth WA 6000</span>
                  <br />

                  <a href="mailto:hello@diviv.com.au">hello@diviv.com.au</a>

                  <br />

                  <a href="tel:1300875978">1300 875 978</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </LayoutWhite>
    </>
  );
}

export default PrivacyPolicy;
